import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, Dialog, DialogProps, IconButton } from "@mui/material";
import React from "react";
import styles from "./OpteraModal.module.css";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
export interface OpteraModalProps extends DialogProps {
	title: string;
	themeColor?: "primary" | "info" | "warning" | "error";
	ariaPrefix: string;
	icon?: React.ReactElement; // should be <typeof Icon> but specifying as such makes TS sad on React.cloneElement
	actionButton?: React.ReactElement; // <typeof Button> same as ^
	secondaryActionButton?: React.ReactElement;
	cancelButtonText?: string;
	onClose: (event: unknown, reason: CloseReason) => void;
	showSecondaryButton?: boolean;
}

/**
 * A pre-styled version of MUI Dialog
 */

function OpteraModal({
	title,
	themeColor = "primary",
	ariaPrefix,
	icon,
	actionButton,
	secondaryActionButton,
	cancelButtonText = "Cancel",
	children,
	showSecondaryButton = true,
	...modalProps
}: OpteraModalProps) {
	let modalIcon = null;

	const iconStyle = {
		width: "53.33px",
		height: "48px",
		color: `var(--oc-palette-${themeColor}-main)`,
	};
	if (themeColor === "error" && icon) {
		modalIcon = React.cloneElement(icon, { sx: iconStyle });
	}
	if (themeColor === "info" && icon) {
		modalIcon = <ErrorOutlineIcon sx={[iconStyle, { rotate: "180deg" }]} />;
	}
	if (themeColor === "warning" && icon) {
		modalIcon = <WarningAmberIcon sx={iconStyle} />;
	}
	if (themeColor === "primary" && icon) {
		modalIcon = React.cloneElement(icon, { sx: iconStyle });
	}

	const handleButtonClose = (e) => modalProps.onClose(e, "closeButtonClick");

	return (
		<Dialog
			aria-labelledby={`${ariaPrefix}-modal-title`}
			aria-describedby={`${ariaPrefix}-modal-description`}
			className={styles.wrapper}
			{...modalProps}
		>
			<div className={styles.title}>
				{modalIcon}
				<h2
					className={themeColor == "warning" ? "" : styles[themeColor]}
					id={`${ariaPrefix}-modal-title`}
				>
					{title}
				</h2>
			</div>
			<IconButton
				aria-label="close"
				onClick={handleButtonClose}
				className={styles.closeIcon}
			>
				<CloseIcon />
			</IconButton>
			<div className={styles.content} id={`${ariaPrefix}-modal-description`}>
				{children}
			</div>
			<div className={styles.actions}>
				{React.cloneElement(actionButton, {
					variant: "contained",
					color: themeColor == "error" ? themeColor : "secondary",
				})}
				{secondaryActionButton && showSecondaryButton ? (
					React.cloneElement(secondaryActionButton, {
						variant: "outlined",
						color: themeColor == "error" ? themeColor : "secondary",
					})
				) : showSecondaryButton ? (
					<Button
						variant="outlined"
						color="secondary"
						aria-label="cancel"
						onClick={handleButtonClose}
					>
						{cancelButtonText}
					</Button>
				) : undefined}
			</div>
		</Dialog>
	);
}

export default OpteraModal;
