import { ListResponse } from "common/types/Monarch";
import {
	AggregateEmissionIntensityResponse,
	BulkUploadResponse,
	CampaignCreateRequestBody,
	ConsumptionEstimateRequest,
	ConsumptionEstimateResponse,
	CreateCampaignResponse,
	DashboardResponse,
	GlobalEstimate,
	IndustryAverageEstimate,
	OperationalEstimateRequest,
	OperationalEstimateResponse,
	ReplyAssociation,
	ReplyContent,
	ReplyContentParams,
	ReplyContentSubmitResponse,
	Respondent,
	RetrieveUrlResponse,
	SurveyCampaignUpdateRequestBody,
	SurveyResponse,
	UpdateLogoResponse,
	VerifyUploadResponse,
} from "common/types/Survey";
import { KyResponse, Options } from "ky";
import slimObjectHash from "slim-object-hash";
import useSWR, { SWRConfiguration } from "swr";
import MonarchClient, {
	prepareSearchParams,
	swrErrorHandle,
} from "./MonarchClient";

class InquireClient {
	static async getSurveyCampaigns(): Promise<ListResponse<SurveyResponse>> {
		return MonarchClient.get("relay/inquire/survey/survey_campaigns/user").then(
			(res) => res.json()
		);
	}

	static async getReplyAssociationsByUserEmail(
		campaign_id: string | number,
		options?: Options
	): Promise<ReplyAssociation[]> {
		return MonarchClient.get(
			`relay/inquire/reply/reply_association/campaign/${campaign_id}/user`,
			options
		).then((res) => res.json());
	}

	static async getReplyAssociations(
		reply_id: string | number,
		options?: Options
	): Promise<ReplyAssociation[]> {
		return MonarchClient.get(
			`relay/inquire/reply/reply_association/reply/${reply_id}`,
			options
		).then((res) => res.json());
	}

	static async updateReplyAssociation(
		reply_association_id: string | number,
		surveyee_email_address: string,
		is_active: boolean,
		options?: Options
	): Promise<ReplyAssociation[]> {
		return MonarchClient.put(
			`relay/inquire/reply/reply_association/${reply_association_id}`,
			{
				json: { surveyee_email_address, is_active },
				...options,
			}
		).then((res) => res.json());
	}

	static async deactivateAllReplyAssociations(
		reply_id: string | number,
		options?: Options
	): Promise<ReplyAssociation[]> {
		return MonarchClient.put(
			`relay/inquire/reply/reply_association/reply_id/${reply_id}/deactivate`,
			options
		).then((res) => res.json());
	}

	static async getSurveyInfo(
		reply_id: string | number,
		options?: Options
	): Promise<SurveyResponse> {
		return MonarchClient.get(
			`relay/inquire/survey/survey_campaign/reply/${reply_id}`,
			options
		).then((res) => res.json());
	}

	static async getAllSurveyCampaigns(
		searchParams?: {
			limit: number;
			offset: number;
		},
		options?: Options
	): Promise<ListResponse<SurveyResponse>> {
		return MonarchClient.get("relay/inquire/survey/survey_campaigns", {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async getSurveyCampaign(
		campaign_id: number,
		options?: Options
	): Promise<SurveyResponse> {
		return MonarchClient.get(
			`relay/inquire/survey/survey_campaign/${campaign_id}`,
			{ ...options }
		).then((res) => res.json());
	}

	static async createSurveyCampaign(
		campaign: CampaignCreateRequestBody,
		options?: Options
	): Promise<CreateCampaignResponse> {
		return MonarchClient.post("relay/inquire/survey/survey_campaigns", {
			...options,
			json: campaign,
		}).then((res) => res.json());
	}

	static async updateSurveyCampaign(
		campaign_id: number,
		campaign: SurveyCampaignUpdateRequestBody,
		options?: Options
	): Promise<unknown> {
		// TODO: API docs don't specify the return shape
		// https://monarch.api.opteraclimate.com/docs/inquire#/survey/update_survey_campaign_survey_survey_campaign__campaign_id__put
		return MonarchClient.put(
			`relay/inquire/survey/survey_campaign/${campaign_id}`,
			{ ...options, json: campaign }
		).then((res) => res.json());
	}

	static async deleteSurveyCampaign(campaign_id: number, options?: Options) {
		return MonarchClient.delete(
			`relay/inquire/survey/survey_campaign/${campaign_id}`,
			options
		).then((res) => res.json());
	}

	static async getContents(
		reply_id: string | number,
		searchParams?: ReplyContentParams,
		options?: Options
	): Promise<ListResponse<ReplyContent>> {
		return MonarchClient.get(`relay/inquire/reply/${reply_id}/reply_content`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async unsubscribeFromList(
		listId: string,
		emailAddress: string,
		options?: Options
	): Promise<void> {
		return MonarchClient.delete(
			`relay/inquire/email_service_provider/list/${listId}/subscriber/${emailAddress}`,
			options
		).then((res) => res.json());
	}

	static async getContent(content_id: string | number): Promise<ReplyContent> {
		return MonarchClient.get(
			`relay/inquire/reply/${content_id}/reply_content`
		).then((res) => res.json());
	}

	static async createContent(
		contentObject: ReplyContent,
		reply_association_id: string | number,
		options?: Options
	): Promise<ReplyContent> {
		return MonarchClient.post(
			`relay/inquire/reply/${reply_association_id}/reply_content`,
			{ ...options, json: contentObject }
		).then((res) => res.json());
	}

	static async updateContent(
		reply_id: string | number,
		current_reply_content_state: ReplyContent,
		new_reply_content_state: ReplyContent,
		options?: Options
	): Promise<ReplyContent> {
		return MonarchClient.put(
			`relay/inquire/reply/${reply_id}/reply_content/${current_reply_content_state.id}`,
			{
				json: { current_reply_content_state, new_reply_content_state },
				...options,
			}
		).then((res) => res.json());
	}

	static async deleteAllContent(reply_id: number, options?: Options) {
		return MonarchClient.delete(
			`relay/inquire/reply/${reply_id}/reply_content/reset`,
			options
		);
	}

	static async deleteContent(
		reply_id: number,
		reply_content_id: number,
		options?: Options
	) {
		return MonarchClient.delete(
			`relay/inquire/reply/${reply_id}/reply_content/${reply_content_id}`,
			options
		);
	}

	static async getIndustryAverageEstimate(
		contentObject: IndustryAverageEstimate,
		options?: Options
	): Promise<GlobalEstimate> {
		return MonarchClient.post(`relay/inquire/estimate/estimate_by_industry`, {
			json: contentObject,
			...options,
		}).then((res) => res.json());
	}

	static async operationalEstimate(
		contentObject: OperationalEstimateRequest[],
		options?: Options
	): Promise<OperationalEstimateResponse> {
		return MonarchClient.post(`relay/inquire/estimate/estimate`, {
			json: contentObject,
			...options,
		}).then((res) => res.json());
	}

	static async consumptionEstimate(
		contentObject:
			| {
					[key: string]: ConsumptionEstimateRequest;
			  }
			| OperationalEstimateRequest[],
		options?: Options
	): Promise<ConsumptionEstimateResponse> {
		return MonarchClient.post(`relay/inquire/estimate/consumption_estimate`, {
			json: contentObject,
			...options,
		}).then((res) => res.json());
	}

	static async submitSurvey(
		reply_id: string | number,
		options?: Options
	): Promise<ReplyContentSubmitResponse> {
		return MonarchClient.put(
			`relay/inquire/reply/${reply_id}/submit`,
			options
		).then((res) => res.json());
	}

	static async getDashboardTable(
		campaign_id: string | number,
		options?: Options
	): Promise<DashboardResponse[]> {
		return MonarchClient.get(
			`relay/inquire/reply/campaign/${campaign_id}/dashboard`,
			options
		).then((res) => res.json());
	}

	static async getRespondentsByCampaign(
		campaign_id: string | number,
		searchParams: {
			search_term: string;
			sort: string;
			limit: number;
			offset: number;
		},
		options?: Options
	): Promise<Respondent[]> {
		return MonarchClient.get(
			`relay/inquire/reply/campaign/${campaign_id}/suppliers/overview`,
			{
				...options,
				searchParams: prepareSearchParams(searchParams),
			}
		).then((res) => res.json());
	}

	static async getSurveyors(
		reply_id: string | number,
		options?: Options
	): Promise<string[]> {
		return MonarchClient.get(
			`relay/inquire/reply/${reply_id}/surveyors`,
			options
		).then((res) => res.json());
	}

	static async getCSV(reply_id: string | number) {
		return MonarchClient.get(`relay/inquire/reply/${reply_id}/csv_export`).then(
			(res) => res.blob()
		);
	}

	static async reassignSurveyUser(
		campaign_id: string | number,
		old_user: string,
		new_user: string,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.patch(
			`relay/inquire/survey/survey-campaign/${campaign_id}/reassign-user`,
			{
				json: { old_user, new_user },
				...options,
			}
		);
	}

	static async verifyBulkUploadRespondents(
		campaign_id: string | number,
		csv_file: File,
		options?: Options
	): Promise<VerifyUploadResponse> {
		let body = new FormData();
		body.append("file", csv_file);
		return MonarchClient.post(
			`relay/inquire/survey/suppliers/verify/${campaign_id}`,
			{
				...options,
				body,
			}
		).then((res) => res.json());
	}

	static async getAggregateEmissions(
		categoryName?: string,
		category?: string,
		year?: string
	): Promise<AggregateEmissionIntensityResponse> {
		if (!categoryName || !category || !year) {
			console.error("called getAggregateEmissions with invalid params", {
				categoryName,
				category,
				year,
			});
			return Promise.resolve({ size: 0 });
		}
		return MonarchClient.get(
			`relay/metage/emission-intensities/aggregate/year/${year}/category/${categoryName}:${category}`
		).then((res) => res.json());
	}

	static async bulkImportRespondents(
		campaign_id: string | number,
		csv_file: File,
		options?: Options
	): Promise<BulkUploadResponse> {
		let body = new FormData();
		body.append("file", csv_file);
		return MonarchClient.post(
			`relay/inquire/survey/suppliers/${campaign_id}/bulk_import`,
			{
				...options,
				body,
			}
		).then((res) => res.json());
	}

	static async uploadCampaignLogo(
		campaign_id: string | number,
		image_file: File,
		options?: Options
	): Promise<UpdateLogoResponse> {
		let body = new FormData();
		body.append("file", image_file);
		return MonarchClient.put(
			`relay/inquire/survey/survey_campaign/${campaign_id}/logo`,
			{
				...options,
				body,
			}
		).then((res) => res.json());
	}

	static async getCampaignLogoUrl(
		campaign_id: string | number,
		options?: Options
	): Promise<RetrieveUrlResponse> {
		return MonarchClient.get(
			`relay/inquire/survey/survey_campaign/${campaign_id}/logo`,
			options
		).then((res) => res.json());
	}
}

export function useSurveyCampaign(
	id: number,
	kyOptions?: Options,
	swrOptions?: SWRConfiguration
) {
	const key = id ? `survey/survey_campaign/${id}` : null;
	const fallbackData: SurveyResponse = {
		campaign_metadata: {
			hasBusinessUnits: false,
			canAddFacilities: false,
			isRBA: true,
			addCBAM: false,
			end_time: "",
			start_time: "",
			thank_you_smart_email_map: {
				dev: "",
				prod: "",
			},
		},
		campaign_name: "",
		campaign_status: "",
		created_at: "",
		esp_list_id: "",
		id: 0,
		modified_at: "",
		orchestrator_id: 0,
		orchestrator_name: "",
		organization_id: 0,
	};
	const result = useSWR<SurveyResponse>(
		key,
		() => InquireClient.getSurveyCampaign(id, kyOptions),
		{
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData,
			...swrOptions,
		}
	);
	swrErrorHandle({ key, result });
	return result;
}

export const useRespondents = (
	campaign_id,
	params?,
	options?: SWRConfiguration
) => {
	const key = `relay/inquire/reply/campaign/${campaign_id}/suppliers/overview?${slimObjectHash(params)}`;
	const result = useSWR(
		key,
		() => InquireClient.getRespondentsByCampaign(campaign_id, params),
		{
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			...options,
		}
	);
	swrErrorHandle({ key, params, result });
	return result;
};

export default InquireClient;
